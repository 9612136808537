import React, { lazy } from 'react';
// Don't code-split if that affects rendering UX?
// import SHORT_TEXT from './CoreFields/SHORT_TEXT';

import { CONTACT_INFO_TYPES } from 'v1/helpers/DropdownDataSets';

// GENERIC Core Fields (by type)
const CURRENCY = lazy(() => import('./CoreFields/CURRENCY'));
const DATE = lazy(() => import('./CoreFields/DATE'));
const LONG_TEXT = lazy(() => import('./CoreFields/LONG_TEXT'));
const MULTI_INPUT = lazy(() => import('./CoreFields/MULTI_INPUT'));
const RESOURCE_CONNECT = lazy(() => import('./CoreFields/RESOURCE_CONNECT'));
const SHORT_TEXT = lazy(() => import('./CoreFields/SHORT_TEXT'));
const TAGS = lazy(() => import('./CoreFields/TAGS'));
const GROUPS = lazy(() => import('./CoreFields/Groups'));

// Specific Core Fields (by name)
const color = lazy(() => import('./CoreFields/color'));
const contact_id = lazy(() => import('./CoreFields/contact_id'));
const contracts = lazy(() => import('./CoreFields/contracts'));
const events = lazy(() => import('./CoreFields/events'));
const expenses = lazy(() => import('./CoreFields/expenses'));
const full_name = lazy(() => import('./CoreFields/full_name'));
const icon = lazy(() => import('./CoreFields/icon'));
const locations = lazy(() => import('./CoreFields/locations'));
const production_date = lazy(() => import('./CoreFields/production_date'));
const rate = lazy(() => import('./CoreFields/rate'));
const rates = lazy(() => import('./CoreFields/rates'));
const resource_slot_assignments = lazy(
  () => import('./CoreFields/resource_slot_assignments')
);
const socials = lazy(() => import('./CoreFields/socials'));
const status_id = lazy(() => import('./CoreFields/status_id'));
const timezone = lazy(() => import('./CoreFields/timezone'));

export default {
  budget_currency: CURRENCY,
  code: ({ id, label, placeholder, value, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'code'}
      label={label !== undefined ? label : 'Code'}
      placeholder={placeholder !== undefined ? placeholder : 'Enter Code'}
      disabled={disabled}
      {...props}
    />
  ),
  color,
  contact_id,
  contracts,
  description: ({ id, label, placeholder, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'description'}
      label={label !== undefined ? label : 'Description'}
      placeholder={
        placeholder !== undefined ? placeholder : 'Enter Description'
      }
      disabled={disabled}
      {...props}
    />
  ),
  emails: ({ label, ...props }) => (
    <MULTI_INPUT
      label={label !== undefined ? label : 'Emails'}
      type="email"
      valueKey="value_1"
      prepend={{
        valueKey: 'value_2',
        options: CONTACT_INFO_TYPES
      }}
      initialValues={{ value_2: CONTACT_INFO_TYPES[0].value }}
      {...props}
    />
  ),
  events,
  expenses,
  // Can eventually be replaced by new date_range component
  end_date: ({ label, ...props }) => (
    <DATE label={label !== undefined ? label : 'Starts'} {...props} />
  ),
  full_name,
  groups: ({ label, placeholder, ...props }) => (
    <GROUPS
      label={label !== undefined ? label : 'Groups'}
      placeholder={placeholder !== undefined ? placeholder : 'Enter Group'}
      fetchTags={false}
      disableAdd
      {...props}
    />
  ),
  icon,
  location_name: ({ id, label, placeholder, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'location_name'}
      label={label !== undefined ? label : 'Location'}
      placeholder={placeholder !== undefined ? placeholder : 'Enter Location'}
      disabled={disabled}
      {...props}
    />
  ),
  locations,
  note: ({ id, label, placeholder, disabled, ...props }) => (
    <LONG_TEXT
      id={id || 'note'}
      label={label !== undefined ? label : 'Note'}
      placeholder={placeholder !== undefined ? placeholder : 'Write a note'}
      disabled={disabled}
      {...props}
    />
  ),
  organisation_id: ({ label, ...props }) => {
    return (
      <RESOURCE_CONNECT
        name="organisation_id"
        label={label !== undefined ? label : 'Organisation'}
        resourceTypeModel="ORGANIZATION"
        {...props}
      />
    );
  },
  phone_numbers: ({ label, ...props }) => (
    <MULTI_INPUT
      label={label !== undefined ? label : 'Phone numbers'}
      type="phone"
      valueKey="value_1"
      prepend={{
        valueKey: 'value_2',
        options: CONTACT_INFO_TYPES
      }}
      initialValues={{ value_2: CONTACT_INFO_TYPES[0].value }}
      {...props}
    />
  ),
  production_code: ({ id, label, placeholder, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'production_code'}
      label={label !== undefined ? label : 'Code'}
      placeholder={placeholder !== undefined ? placeholder : 'TBC'}
      disabled={disabled}
      {...props}
    />
  ),
  production_date,
  rate,
  rates,
  represented_by_id: ({ label, ...props }) => (
    <RESOURCE_CONNECT
      name="represented_by_id"
      label={label !== undefined ? label : 'Agent'}
      resourceTypeModel="AGENT"
      {...props}
    />
  ),
  resource_slot_assignments,
  role: ({ id, label, placeholder, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'role'}
      label={label !== undefined ? label : 'Role'}
      placeholder={placeholder !== undefined ? placeholder : 'Enter Role'}
      disabled={disabled}
      {...props}
    />
  ),
  socials,
  // Can eventually be replaced by new date_range component
  start_date: ({ label, disabled, ...props }) => (
    <DATE label={label !== undefined ? label : 'Starts'} {...props} />
  ),
  status_id,
  title: ({ id, label, placeholder, disabled, ...props }) => (
    <SHORT_TEXT
      id={id || 'title'}
      label={label !== undefined ? label : 'Title'}
      placeholder={placeholder !== undefined ? placeholder : 'Title'}
      disabled={disabled}
      {...props}
    />
  ),
  timezone,
  tags: TAGS,
  websites: ({ label, ...props }) => (
    <MULTI_INPUT
      label={label !== undefined ? label : 'Websites'}
      type="url"
      valueKey="value_1"
      prepend={{
        valueKey: 'value_2',
        options: CONTACT_INFO_TYPES
      }}
      initialValues={{ value_2: CONTACT_INFO_TYPES[0].value }}
      {...props}
    />
  )
};
