import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce, find, get } from 'lodash';
import moment from 'moment';

import {
  getCustomFieldDefinitions,
  getCustomFieldGroupDefinitions,
  makeGetResourceFields
} from 'store/v1/custom_fields/custom_fields.selectors.js';
import { getGroupList } from 'store/v1/groups/groups.selectors.js';
import {
  createContact,
  updateContact,
  checkContactDuplicates
} from 'store/v1/contacts/contacts.actions.js';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';

import { Capability } from '__types__';
import RESOURCE_CONFIG from 'v1/helpers/resourceTypeHelper';

import FormBuilder from 'v1/components/FormBuilder/FormBuilder';
import {
  ProfileImageUploader,
  ResourceDuplicates,
  ResourceTypeSelectorDisplay
} from 'v1/components/shared';

import styles from './ResourcePaneDetails.module.scss';
import { useTags } from 'store/v1/tags/tags.hooks';

const ResourcePaneDetails = ({
  resource,
  resourceKey,
  autosave,
  isReadOnly,
  setFormApi
}) => {
  const dispatch = useDispatch();
  const resourceTypes = useSelector(state =>
    getResourceTypes(state, { archived: true })
  );
  const [resourceTypeId, setResourceTypeId] = useState(
    resource.resource_type_id
  );
  const resourceType = find(resourceTypes, r => r.id === resourceTypeId);
  const customFieldDefinitions = useSelector(getCustomFieldDefinitions);
  const customFieldGroupDefinitions = useSelector(
    getCustomFieldGroupDefinitions
  );
  const getFields = useMemo(makeGetResourceFields, []);
  const fields = useSelector(state =>
    getFields(state, {
      typeId: resourceTypeId
    })
  );
  const groupList = useSelector(getGroupList);
  const groups = groupList.filter(
    g => !g.archived && g.entity_id === resource.resource_type_id
  );
  const tagList = useTags('resource'); //myTagsService here

  const fieldOptions = {
    className: 'stack-M',
    appearance: 'underline',
    size: 'S',
    fieldOverrides: {
      full_name: {
        showSingleNameField: get(RESOURCE_CONFIG, [
          get(resourceType, 'model'),
          'resourcePageLayout',
          'showSingleNameField'
        ])
      },
      groups: {
        suggestions: groups
      },
      tags: {
        suggestions: tagList
      }
    }
  };
  const [lastFirstName, setLastFirstName] = useState();
  const [lastLastName, setLastLastName] = useState();

  const checkForDuplicates = useCallback(
    debounce(data => dispatch(checkContactDuplicates(data)), 300),
    []
  );

  useEffect(() => {
    setResourceTypeId(resource.resource_type_id);
  }, [resource]);

  const handleResourceTypeChange = (id, setValue) => {
    setValue('resource_type_id', id);
    setResourceTypeId(id);
  };

  const handleChange = ({ values }) => {
    if (
      !values.id &&
      (values.first_name !== lastFirstName || values.last_name !== lastLastName)
    ) {
      setLastFirstName(values.first_name);
      setLastLastName(values.last_name);

      if (
        (get(resourceType, 'model') === 'LOCATION' ||
          get(resourceType, 'model') === 'ORGANIZATION' ||
          get(resourceType, 'model') === 'ITEM') &&
        values.first_name
      ) {
        checkForDuplicates(values);
      }
      if (
        (get(resourceType, 'model') === 'INDIVIDUAL' ||
          get(resourceType, 'model') === 'AGENT' ||
          get(resourceType, 'model') === 'TALENT') &&
        values.first_name &&
        values.last_name
      ) {
        checkForDuplicates(values);
      }
    }
  };

  const handleSubmit = data => {
    resource.id
      ? dispatch(updateContact(resource.id, data))
      : dispatch(createContact(data));
  };

  return (
    <div className={styles.ResourcePaneDetails}>
      <div className={classnames(['inset-M', styles.ResourcePaneDetailsForm])}>
        <FormBuilder
          autoSave={autosave}
          key={resourceKey || `${resource.id}-${resource.resource_type_id}`}
          data={resource}
          fields={fields}
          customFieldDefinitions={customFieldDefinitions}
          customFieldGroupDefinitions={customFieldGroupDefinitions}
          onChange={handleChange}
          onSubmit={handleSubmit}
          disabled={isReadOnly}
          getApi={setFormApi ? api => setFormApi(api) : null}
        >
          {({ renderForm, getValue, setValue }) => (
            <>
              {/* If we're creating a new resource, we don't show the header, so we need a resource type selector & profile image uploader */}
              {!resource.id && (
                <>
                  <div className="stack-S">
                    <ResourceTypeSelectorDisplay
                      label={'Add '}
                      size="L"
                      resourceTypeId={getValue('resource_type_id')}
                      onChange={id => handleResourceTypeChange(id, setValue)}
                    />
                  </div>
                  {resource.resource_type_id && (
                    <div className="stack-S">
                      <ProfileImageUploader
                        src={getValue('profile_picture')}
                        capability={Capability.RESOURCE_CREATE}
                        onRemoveImage={(f, value) =>
                          setValue('profile_picture', value)
                        }
                        onUpload={(f, value) =>
                          setValue('profile_picture', value)
                        }
                        label="Add Profile Image"
                      />
                    </div>
                  )}
                </>
              )}
              {renderForm(fieldOptions)}
            </>
          )}
        </FormBuilder>
      </div>
      {!resource.id && (
        <div className={styles.ResourcePaneDetailsDuplicates}>
          <ResourceDuplicates />
        </div>
      )}
      {resource.id && (
        <div className={styles.ResourcePaneDetailsMeta}>
          <span
            title={moment(resource.updated_at).format('MM/DD/YYYY HH:mm:ss')}
          >
            Updated {moment(resource.updated_at).fromNow()}
          </span>
          {' · '}
          <span
            title={moment(resource.created_at).format('MM/DD/YYYY HH:mm:ss')}
          >
            Created {moment(resource.created_at).fromNow()}
          </span>
        </div>
      )}
    </div>
  );
};

ResourcePaneDetails.props = {
  resource: PropTypes.object,
  resourceKey: PropTypes.string,
  autosave: PropTypes.bool,
  isReadOnly: PropTypes.any,
  setFormApi: PropTypes.func
};

export default ResourcePaneDetails;
