import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PlusIcon } from '@heroicons/react/24/solid/index';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ComponentProps,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  useLayoutEffect,
  useRef
} from 'react';
import { getSiblings } from 'v5/platform/dom/getSiblings';
import { Overwrite } from 'v5/platform/typescript'; // core
import { Pill, PillProps } from './Pill';
import { EditableInputMode } from './types';

type DraggablePillProps = Overwrite<
  PillProps,
  { id: string; onRemove: () => void }
>;

const MotionPill = motion(Pill);

const DraggablePill = forwardRef(
  (
    { id, mode, children, onRemove, ...rest }: DraggablePillProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({ id, disabled: mode === 'display' || rest.disabled });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      zIndex: isDragging ? 1 : 0
    };
    const refToFocusNextRender = useRef<HTMLElement | null>(null);
    useLayoutEffect(() => () => {
      if (refToFocusNextRender.current) {
        refToFocusNextRender.current.focus();
        refToFocusNextRender.current = null;
      }
    });

    function handleRemove(event: MouseEvent | KeyboardEvent) {
      if (isDragging) return;
      const listItem = event.currentTarget.closest('[role="listitem"]');
      const list = listItem?.closest('[role="list"]');
      if (!list || !listItem) return;
      const { prevElement, nextElement } = getSiblings(
        list,
        event.currentTarget,
        '[role="listitem"]'
      );
      if (onRemove) {
        const elementToFocus = nextElement ?? prevElement;
        if (elementToFocus instanceof HTMLElement) {
          refToFocusNextRender.current = elementToFocus;
        }
        onRemove();
      }
    }

    return (
      <div
        ref={ref}
        style={style}
        {...{ ...attributes, ...listeners }}
        tabIndex={-1}
      >
        <Pill
          role="listitem"
          ref={setNodeRef}
          className={isDragging ? 'tw-shadow-lg tw-scale-110' : ''}
          mode={mode}
          onRemove={handleRemove}
          {...rest}
        >
          {children}
        </Pill>
      </div>
    );
  }
);
export interface TagGroupInputProps<T> {
  data: T[];
  onAdd?: () => void | Promise<string>;
  onRemove: (id: string, index: number) => void;
  onMove: (oldIndex: number, newIndex: number) => void;
  mode: EditableInputMode;
  rowRenderer: (data: T, index: number) => ReactNode;
  renderCreateButton?: (children: ReactElement) => ReactNode;
  getTagProps?: (data: T) => Partial<ComponentProps<typeof DraggablePill>>;
  getCreateTagProps?: () => Partial<ComponentProps<typeof MotionPill>>;
  getId: (data: T) => string;
  className?: string;
  addLabel?: ReactNode;
  placeholder?: ReactNode;
  draggable?: boolean;
  onPressDownOnTag?: (e: KeyboardEvent) => void;
}
export function TagGroupInput<T>({
  data,
  onAdd,
  onRemove,
  onMove,
  mode,
  rowRenderer,
  getId,
  className,
  addLabel = 'Add',
  draggable = true,
  getTagProps,
  getCreateTagProps,
  placeholder,
  onPressDownOnTag,
  renderCreateButton = children => children
}: TagGroupInputProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3
      }
    }),
    useSensor(KeyboardSensor, {
      // todo: we need custom logic to keep swapping only adjacent elements
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = data.findIndex(item => getId(item) === active.id);
      const newIndex = data.findIndex(item => getId(item) === over?.id);
      onMove(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdges]}
    >
      <SortableContext
        items={data.map(getId)}
        strategy={horizontalListSortingStrategy}
        disabled={!draggable}
      >
        <div
          role="list"
          className={classNames(
            'tw-flex tw-flex-row tw-gap-md tw-flex-wrap',
            className
          )}
        >
          {data.map((item, i) => {
            const id = getId(item);
            return (
              <DraggablePill
                draggable={draggable}
                onKeyDown={e => {
                  if (e.key === 'ArrowDown') {
                    onPressDownOnTag?.(e);
                  }
                }}
                key={id}
                data-id={id}
                id={id}
                data-satie-portal-hack
                mode={mode}
                onRemove={() => {
                  onRemove(
                    id,
                    data.findIndex(i => getId(i) === id)
                  );
                }}
                {...getTagProps?.(item)}
              >
                {rowRenderer(item, i)}
              </DraggablePill>
            );
          })}
          <AnimatePresence>
            {(mode === 'edit' || data.length === 0) &&
              renderCreateButton(
                <MotionPill
                  styleVariant="subtle"
                  key="add"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  mode="display"
                  onClick={() => {
                    if (!onAdd) return;
                    const maybeId = onAdd();

                    if (maybeId instanceof Promise) {
                      maybeId.then(id => {
                        document
                          .querySelector<HTMLElement>(`[data-id="${id}"]`)
                          ?.focus();
                      });
                    }
                  }}
                  {...getCreateTagProps?.()}
                >
                  <PlusIcon className="tw-w-5" />
                  <span className={'tw-leading-none'}>{addLabel}</span>
                </MotionPill>
              )}
            {mode === 'display' && data.length === 0 && placeholder && (
              <motion.span
                className="tw-text-base tw-font-semibold tw-text-fg-muted tw-my-sm tw-mx-sm"
                key="tooltip"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: 'tween',
                  duration: 0.1
                }}
              >
                {placeholder}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
      </SortableContext>
    </DndContext>
  );
}
