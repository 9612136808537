import { XMarkIcon } from '@heroicons/react/24/outline/index';
import classNames from 'classnames';
import {
  ButtonHTMLAttributes,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode
} from 'react';
import { Overwrite } from 'v5/platform/typescript';
import Icon from '../../shared/general/Icon/Icon';
import { EditableInputMode } from './types';

export type PillStyleVariant = 'default' | 'subtle' | 'highlighted';

export type PillProps = Overwrite<
  ButtonHTMLAttributes<HTMLButtonElement>,
  {
    mode: EditableInputMode;
    children: ReactNode;
    onRemove?: (event: MouseEvent | KeyboardEvent) => void;
    styleVariant?: PillStyleVariant;
    radius?: 'full' | 'sm';
    draggable?: boolean;
  }
>;

export const Pill = forwardRef<HTMLButtonElement, PillProps>(
  (
    {
      styleVariant = 'default',
      radius = 'full',
      mode,
      onRemove,
      className,
      children,
      draggable = true,
      ...rest
    },
    ref
  ) => (
    <button
      type="button"
      tabIndex={0} // focusable element
      className={classNames(
        'tw-group/pill',
        'tw-border-none tw-p-0',
        'tw-transition tw-duration-200',
        'tw-text-base tw-font-sans tw-font-semibold tw-flex tw-flex-row tw-items-center tw-ring-1',
        {
          full: 'tw-rounded-full',
          sm: 'tw-rounded'
        }[radius],
        {
          default: 'tw-ring-border-default dark:tw-ring-gray-700',
          subtle:
            'tw-ring-border-default [&_*]:tw-text-fg-default tw-bg-bg-subtle',
          highlighted: 'tw-ring-border-muted dark:tw-ring-gray-500'
        }[styleVariant],
        // focus ring
        'tw-outline-none focus:tw-outline-none tw-ring-1',
        mode === 'edit'
          ? 'focus:tw-ring-fg-default dark:focus:tw-ring-gray-300'
          : 'focus-visible:tw-ring-fg-default dark:focus-visible:tw-ring-gray-300',
        // hover in display mode
        mode === 'display'
          ? 'tw-text-fg-subtle dark:tw-text-gray-800'
          : 'tw-text-fg-default dark:tw-text-gray-300',
        // background
        {
          default:
            'tw-bg-bg-default dark:tw-bg-gray-800 hover:tw-bg-gray-50 active:tw-bg-gray-100',
          subtle:
            'tw-bg-bg-default dark:tw-bg-gray-800 hover:tw-bg-gray-50 active:tw-bg-gray-100',
          highlighted:
            'tw-bg-bg-subtle dark:tw-bg-gray-700 hover:tw-bg-gray-50 active:tw-bg-gray-100'
        }[styleVariant],
        // text selection
        'tw-select-none',
        className,
        !draggable && 'tw-pl-2'
      )}
      ref={ref}
      {...rest}
      onKeyDown={e => {
        if (mode === 'edit' && e.key === 'Backspace') {
          onRemove?.(e);
        } else if (e.key === 'ArrowLeft') {
          const sibling = e.currentTarget.parentElement?.previousElementSibling;
          if (sibling instanceof HTMLElement) {
            if (sibling.firstChild instanceof HTMLElement) {
              sibling.firstChild.focus();
              e.stopPropagation();
            }
          }
        } else if (e.key === 'ArrowRight') {
          const sibling = e.currentTarget.parentElement?.nextElementSibling;
          if (sibling instanceof HTMLElement) {
            if (sibling.firstChild instanceof HTMLElement) {
              sibling.firstChild.focus();
              e.stopPropagation();
            }
          }
        } else {
          rest.onKeyDown?.(e);
        }
      }}
    >
      {draggable && (
        <div
          className={classNames(
            'tw-flex tw-items-center tw-justify-center',
            {
              full: 'tw-rounded-l-full',
              sm: 'tw-rounded-l'
            }[radius],
            'tw-text-fg-subtle dark:tw-text-gray-300 tw-py-sm',
            'group-focus/tw-pill:tw-text-fg-default dark:group-focus/tw-pill:tw-text-gray-300', // focus effect
            'tw-transition-all',
            mode === 'edit'
              ? 'tw-w-6 tw-pl-lg tw-pr-sm '
              : 'tw-opacity-0 tw-w-0 tw-mx-0 tw-px-sm'
          )}
        >
          <Icon name="drag" className={classNames('tw-w-3 tw-h-3')} />
        </div>
      )}
      <div
        className={classNames(
          'tw-px-sm tw-py-sm tw-whitespace-nowrap tw-flex tw-flex-row tw-items-center tw-gap-sm',
          {
            default: 'tw-text-fg-default dark:tw-text-gray-300',
            highlighted: 'tw-text-fg-default dark:tw-text-gray-300',
            subtle: 'tw-text-fg-muted dark:tw-text-gray-400'
          }[styleVariant]
        )}
      >
        {children}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={e => {
          e.stopPropagation();
          onRemove?.(e);
        }}
        className={classNames(
          'tw-self-stretch tw-flex tw-items-center tw-justify-center',
          {
            full: 'tw-rounded-r-full',
            sm: 'tw-rounded-r'
          }[radius],
          'tw-text-fg-subtle tw-py-sm',
          'hover:tw-bg-bg-subtle dark:hover:tw-bg-gray-700 hover/tw-pill:tw-text-gray-400 dark:hover/tw-pill:tw-text-gray-300', // hover effect
          'group-focus/tw-pill:tw-text-fg-default dark:group-focus/tw-pill:tw-text-gray-200', // focus effect
          mode === 'edit'
            ? ' tw-w-6 tw-pl-0.5 tw-pr-2 '
            : 'tw-pointer-events-none tw-opacity-0 tw-w-0 tw-mx-0 tw-px-sm'
        )}
      >
        <XMarkIcon className={classNames('tw-w-xl tw-h-xl')} />
      </div>
    </button>
  )
);
