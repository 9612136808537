import React from 'react';
import { injectConfig } from '../../../../v5/platform/react/injectConfig';
import {
  CollectionField,
  CollectionFieldProps
} from '../../../../v5/common-widgets/CollectionField';
import { rd } from '@easle/monads';
import { createMatcher } from '@passionware/query-toolkit';

type MultiOption = {
  id: number;
  custom_field_definition_id: number;
  order: number;
  value: string;
};

type MultiOptionsProps = CollectionFieldProps<MultiOption> & {
  options: MultiOption[];
  tagIds: string[];
};

const Field = injectConfig(CollectionField<MultiOption>)
  .fromProps<MultiOptionsProps>(api => ({
    useSelectedItems: keys => {
      const props = api.useProps();
      return rd.of(
        keys.map(key => props.options.find(x => x.id === Number(key))!)
      );
    },
    useOptions: query => {
      const props = api.useProps();
      return rd.of(
        props.options
          .filter(createMatcher(query, x => x.value))
          .map(x => ({ id: x.id.toString(), label: x.value }))
      );
    },
    useResolveById: () => {
      const props = api.useProps();
      return id => {
        const option = props.options.find(x => x.id === Number(id));

        if (!option) throw new Error('Option not found');

        return Promise.resolve(option);
      };
    },
    promptRenderer: data => (data.length === 0 ? 'Add' : 'Add'),
    useCreateContent: (query, options) => {
      return undefined;
    },
    useCreatePromise: () => query => {
      throw new Error('Multiselect doesnt support create');
    },
    getId: x => x.id.toString(),
    rowRenderer: x => <div>{x.value}</div>,
    useGetGhostItem: () => query => ({
      id: 0,
      custom_field_definition_id: 0,
      order: 0,
      value: query
    })
  }))
  .transformProps(x => x.skipFields('options', 'tagIds'));

export const MultiselectCollectionField = (props: MultiOptionsProps) => {
  return <Field {...props} options={props.options} data={props.tagIds} />;
};
