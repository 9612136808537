import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, find, omit } from 'lodash';

import { getTags, selectTagsStore } from 'store/v1/tags';
import { isValue, orderReducer } from 'v1/helpers/misc';
import { SearchField } from 'v1/components/shared';
import { useTags } from 'store/v1/tags/tags.hooks';

const ResourceFieldFilters = ({
  className,
  resourceTypeId,
  query,
  filterList,
  onFilterChange
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const resource_types = useSelector(state => state.resource_types);
  const resourceTypes = orderReducer(resource_types);
  const tagsStore = useSelector(selectTagsStore);
  const tagsList = useTags('resource'); //myTagsService here
  const queryFilters = get(query, 'filters');
  const customFields = get(auth, 'settings.custom_field_definitions');
  const selectedResourceType = resourceTypes.find(r => r.id === resourceTypeId);
  const filterSource =
    filterList ||
    get(selectedResourceType, ['metastructure', 'fields']) ||
    get(selectedResourceType, ['metastructure', 'field_order']);

  useEffect(() => {
    filterSource.find(f => f === 'tags' || f.name === 'tags') &&
      !tagsStore.fetched &&
      dispatch(getTags());
  }, []);

  const handleUpdate = (key, method, value, fieldType) => {
    let newQuery = {};
    if (isValue(value)) {
      let filter;
      if (fieldType) {
        filter = {
          groups: get(queryFilters, 'filters.groups'),
          [method]: value,
          custom_field_definition_type: fieldType
        };
      } else {
        filter = { [method]: value };
      }
      newQuery = { filters: { ...queryFilters, [key]: filter } };
    } else {
      newQuery = { filters: omit(queryFilters, key) };
    }
    onFilterChange(newQuery);
  };

  const getCustomFieldObject = customFieldId => {
    const customFieldObject = find(customFields, c => c.id === customFieldId);
    if (customFieldObject && customFieldObject.filterable) {
      return {
        key: `custom_${customFieldObject.id}`,
        label: customFieldObject.name,
        data_type: customFieldObject.data_type,
        custom_field_definition_type: customFieldObject.data_type,
        items: customFieldObject.options
      };
    }
    return false;
  };

  const getFilterList = () => {
    return filterSource.reduce((acc, keyValue) => {
      let customFieldId;

      if (typeof keyValue === 'string') {
        if (keyValue.startsWith('custom_')) {
          customFieldId = parseInt(keyValue.replace('custom_', ''));
        }
      } else {
        customFieldId = keyValue.custom_field_definition_id;
      }
      const key = typeof keyValue === 'string' ? keyValue : keyValue.name;

      let field;
      if (customFieldId) {
        field = getCustomFieldObject(customFieldId);
      } else {
        switch (key) {
          case 'locations':
            field = {
              key: 'locations',
              label: 'Location',
              data_type: 'SHORT_TEXT'
            };
            break;
          case 'tags':
            field = {
              key: 'tags',
              label: 'Tags',
              data_type: 'TAG_SELECT',
              tagSuggestions: tagsList
            };
            break;
          case 'rates':
            field = {
              key: 'rates',
              label: 'Rates',
              data_type: 'CURRENCY'
            };
            break;
          default:
            break;
        }
      }
      return field ? [...acc, field] : acc;
    }, []);
  };

  const renderField = (field, index) => {
    if (field) {
      if (field.custom_field_definition_type) {
        return (
          <SearchField
            key={`${field.key}-${index}`}
            field={field}
            fieldKey={field.key}
            onUpdate={handleUpdate}
            query={query}
            isCustom
          />
        );
      }
      return (
        <SearchField
          key={`${field.key}-${index}`}
          field={field}
          fieldKey={field.key}
          onUpdate={handleUpdate}
          query={query}
          tags={tagsStore}
        />
      );
    }
    return null;
  };

  const renderFields = () => {
    return getFilterList().map((field, index) => renderField(field, index));
  };

  return (
    <div className={classnames(['ResourceFieldFilters', className])}>
      {renderFields()}
    </div>
  );
};

ResourceFieldFilters.propTypes = {
  className: PropTypes.string,
  resourceTypeId: PropTypes.any,
  query: PropTypes.object.isRequired,
  filterList: PropTypes.arrayOf(PropTypes.string),
  onFilterChange: PropTypes.func // (query)
};

ResourceFieldFilters.props = {};

export default ResourceFieldFilters;
