import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  ReactNode,
  FC,
  CSSProperties,
  RefObject,
  useContext
} from 'react';
import classnames from 'classnames';
import Portal from '@reach/portal';

import { getStyles } from 'v1/helpers/popovers';

interface PopoverContextType {
  showPopover: boolean;
  setShowPopover: (show: boolean) => void;
  trigger: RefObject<HTMLElement> | null;
  setTrigger: (ref: RefObject<HTMLElement>) => void;
  content: RefObject<HTMLElement> | null;
  setContent: (ref: RefObject<HTMLElement>) => void;
}

const PopoverContext = createContext<PopoverContextType | undefined>(undefined);

interface PopoverProps {
  children: ReactNode;
  onPopoverClose?: () => void;
}

const Popover: FC<PopoverProps> = ({ children, onPopoverClose }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [trigger, setTrigger] = useState<RefObject<HTMLElement> | null>(null);
  const [content, setContent] = useState<RefObject<HTMLElement> | null>(null);

  const initialValue: PopoverContextType = {
    showPopover,
    setShowPopover,
    trigger,
    setTrigger,
    content,
    setContent
  };

  const handleClickOutsideRef = useRef<(event: MouseEvent) => void>();
  handleClickOutsideRef.current = (event: MouseEvent) => {
    if (
      showPopover &&
      content?.current &&
      !content.current.contains(event.target as Node) &&
      !(event.target as HTMLElement).className.includes('react-datepicker') &&
      !(event.target as HTMLElement).className.includes('MenuItem-label') &&
      !(event.target as HTMLElement).closest('[data-floating-ui-portal]')
    ) {
      onPopoverClose && onPopoverClose();
      setShowPopover(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      handleClickOutsideRef.current!(event);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showPopover, content]);

  return (
    <PopoverContext.Provider value={initialValue}>
      {children}
    </PopoverContext.Provider>
  );
};

interface PopoverTriggerProps {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
}

const PopoverTrigger: FC<PopoverTriggerProps> = ({
  className,
  disabled,
  children
}) => {
  const { showPopover, setShowPopover, setTrigger } =
    useContext(PopoverContext)!; // todo add context presence and remove (!) from the end
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => setTrigger(triggerRef), []);

  return (
    <div
      ref={triggerRef}
      onClick={() => !disabled && setShowPopover(!showPopover)}
      className={classnames(['PopoverTrigger', className])}
    >
      {children}
    </div>
  );
};

export type ContentRenderer = ({
  closePopover
}: {
  closePopover: () => void;
}) => ReactNode;

interface PopoverContentProps {
  className?: string;
  children: ReactNode | ContentRenderer;
  method?: string;
}
const PopoverContent: FC<PopoverContentProps> = ({
  className,
  children,
  method
}) => {
  const [styles, setStyles] = useState<CSSProperties>({});
  const { showPopover, setShowPopover, trigger, content, setContent } =
    useContext(PopoverContext)!; // todo add context presence and remove (!) from the end
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => setContent(contentRef), []);

  useEffect(() => {
    setStyles(
      getStyles(
        trigger && trigger.current
          ? trigger.current.getBoundingClientRect()
          : null,
        content && content.current
          ? content.current.getBoundingClientRect()
          : null,
        method
      )
    );
  }, [showPopover, trigger, content, children]);

  return (
    <Portal>
      {showPopover && (
        <div
          data-reach-menu
          ref={contentRef}
          style={styles}
          className={classnames(['PopoverContent', className])}
        >
          {typeof children === 'function'
            ? (children as ContentRenderer)({
                closePopover: () => setShowPopover(false)
              })
            : children}
        </div>
      )}
    </Portal>
  );
};

interface PopoverSectionProps {
  title: string;
  children: ReactNode;
}

const PopoverSection: FC<PopoverSectionProps> = ({ title, children }) => (
  <div className="PopoverSection">
    <div className="PopoverSection_title text-11-700">{title}</div>
    <div className="PopoverSection_content">{children}</div>
  </div>
);

export { Popover, PopoverTrigger, PopoverContent, PopoverSection };
