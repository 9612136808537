import { rd, RemoteData } from '@easle/monads';
import { DropdownMenu } from '@easle/ui/dropdown-menu';
import { FloatingPortal } from '@floating-ui/react';
import { times } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { OptionsLayout } from '../../v4/shared/components/forms/input/primitvies/OptionsLayout';
import { Skeleton } from '../design-sytem/Skeleton';
import { ItemPrimitive } from '../primitives/ItemPrimitive';
import { PopoverLayout } from '../primitives/PopoverLayout';
import { PopoverPrimitive } from '../primitives/PopoverPrimitive';
import { SearchPrimitive } from '../primitives/SearchPrimitive';

export interface ItemSelectorProps {
  config: {
    // use injectConfig
    useOptions: (
      query: string
    ) => RemoteData<{ id: string; label: string; disabled?: boolean }[]>; // tbd if <T> with resolvers
    useCreateContent?: (
      query: string,
      options: RemoteData<{ id: string; label: string }[]>
    ) => ReactNode;
  };
  placeholder?: string;
  children: ReactElement;
  allowCreate?: boolean;
  allowSearch?: boolean;
  closeOnSelect?: boolean;
  onCreate?: (query: string) => Promise<void>;
  onSelect?: (id: string) => void;
  onBackspaceWhenEmptyQuery?: () => void;
}

// todo hide create button when no query or same option exists

export function useDefaultCreateContent(
  query: string,
  options: RemoteData<{ label: string }[]>,
  createContent: ReactNode
) {
  if (query.trim() === '') {
    return null;
  }
  if (
    options.status === 'success' &&
    options.data.some(x => x.label === query)
  ) {
    return null;
  }
  return createContent;
}

const useData = (query: string, config: ItemSelectorProps['config']) => {
  const options = config.useOptions(query);
  const defaultCreateContent = useDefaultCreateContent(
    query,
    options,
    <>
      Create <strong>{query}</strong>
    </>
  );
  const createContent =
    config.useCreateContent?.(query, options) ?? defaultCreateContent;
  return { options, createContent };
};

export function ItemSelector(props: ItemSelectorProps) {
  const useOptions = (query: string) => useData(query, props.config);
  const allowSearch = props.allowSearch ?? true;
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>{props.children}</DropdownMenu.Trigger>
      <FloatingPortal>
        <DropdownMenu.Popover>
          <PopoverPrimitive
            className="tw-z-[1000] tw-max-h-[50vh] tw-min-w-[16rem]"
            layout="fit-content"
          >
            <DropdownMenu.SearchList useOptions={useOptions}>
              {({ options, createContent }, query) => (
                <PopoverLayout
                  searchSlot={
                   allowSearch &&
                    <DropdownMenu.Search>
                      <SearchPrimitive
                        type="text"
                        value={query}
                        placeholder={props.placeholder}
                        onKeyDown={e => {
                          if (props.onBackspaceWhenEmptyQuery) {
                            if (e.key === 'Backspace' && query === '') {
                              props.onBackspaceWhenEmptyQuery();
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }
                          if (e.key === 'Escape') {
                            (
                              window as any
                            ).atellioV1PreventNextPortalCloseOnEscape = true;
                          }
                        }}
                      />
                    </DropdownMenu.Search>
                  }
                  footerSlot={
                    props.allowCreate &&
                    createContent &&
                    rd
                      .journey(options)
                      .wait(
                        <Skeleton className="tw-w-full tw-h-8 tw-rounded-t-none" />
                      )
                      .catch(() => null)
                      .done(() => (
                        <DropdownMenu.Item
                          id="-create"
                          onClick={() => {
                            props.onCreate?.(query);
                            (
                              window as any
                            ).atellioV1PreventNextPortalCloseOnEscape = true;
                          }}
                          closeOnSelect={props.closeOnSelect}
                        >
                          <ItemPrimitive className="tw-py-4 tw-mx-2 tw-mb-2 tw-border-t tw-border-1 tw-border-border-default">
                            {createContent}
                          </ItemPrimitive>
                        </DropdownMenu.Item>
                      ))
                  }
                >
                  {/*TODO We need own options layout with own tailwind classes*/}
                  <OptionsLayout
                    renderLoading={() => (
                      <>
                        {times(11).map(i => (
                          <Skeleton key={i} className="tw-w-full tw-h-8" />
                        ))}
                      </>
                    )}
                    optionsState={options}
                    dimmedContainerClassName="tw-space-y-2"
                    renderOptions={options => (
                      <>
                        {options.map(option => (
                          <DropdownMenu.Item
                            key={option.id}
                            onClick={() => {
                              props.onSelect?.(option.id);
                            }}
                            closeOnSelect={props.closeOnSelect}
                            disabled={option.disabled}
                          >
                            <ItemPrimitive>{option.label}</ItemPrimitive>
                          </DropdownMenu.Item>
                        ))}
                        {options.length === 0 && (
                          <ItemPrimitive>No matches found.</ItemPrimitive>
                        )}
                      </>
                    )}
                  />
                </PopoverLayout>
              )}
            </DropdownMenu.SearchList>
          </PopoverPrimitive>
        </DropdownMenu.Popover>
      </FloatingPortal>
    </DropdownMenu>
  );
}
